'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box, ButtonPill, IconArrowRight, px2rem, spacing } from '@fortum/elemental-ui'

import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { Link, useRouter } from '@/shared/navigation'
import { useGlobalStore } from '@/shared/store/provider'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { AdditionalInfoForm } from './AdditionalInfoForm'
import { HousingSelect } from './HousingSelect'
import { Consumption } from './parts/Consumption'
import { Input } from './parts/Input'
import { QuickSearchWrapper } from './parts/QuickSearchWrapper'
import type { HousingOptions, QuickSearchForm, QuickSearchProps } from './types'
import { QUICK_SEARCH_CONFIG } from './utils'

export const QuickSearchPrivate: React.FC<QuickSearchProps> = ({
  layout = 'responsive',
  primaryLink,
}) => {
  const [postalCode, setPostalCode] = useState<string>()
  const [streetAddress, setStreetAddress] = useState<string>()
  const [isAdditionalFormVisible, setIsAdditionalFormVisible] = useState(false)
  const [isPriceAreaError, setIsPriceAreaError] = useState(false)
  const [place, setPlace] = useState<string>()
  const [isConsumptionInputManual, setIsConsumptionInputManual] = useState(false)
  const l = useTranslations('quickSearchForm.labels')
  const router = useRouter()
  const flexDirection = layout === 'vertical' ? 'column' : { default: 'column', l: 'row' }

  const {
    postalCode: priceAreaPostalCode,
    housingSize,
    housingType,
    usageOption,
    estimatedConsumption,
    deliveryArea,
    _isLoading,
    isMultipleAreas,
    setEstimatedConsumption,
    setHousingSize,
    setHousingType,
    setDeliveryArea,
    setPostalCode: setPriceAreaPostalCode,
    requestDeliveryArea,
    resetHousingInfo,
  } = useGlobalStore((state) => state.housingInfo)

  const shouldDisplayConsumptionRow =
    estimatedConsumption || primaryLink || isConsumptionInputManual

  const form = useForm<QuickSearchForm>({
    mode: 'onTouched',
    values: {
      postalCode: postalCode || '',
      streetAddress: streetAddress || '',
      city: place || '',
      housingSize: housingSize ? String(housingSize) : '',
      housingType,
      estimatedConsumption: estimatedConsumption ? String(estimatedConsumption) : '',
    },
  })

  const resetInnerState = () => {
    setPlace(undefined)
    setStreetAddress(undefined)
    setIsAdditionalFormVisible(false)
    setIsPriceAreaError(false)
  }

  const onChange = (name: keyof QuickSearchForm, value: string) => {
    switch (name) {
      case 'postalCode':
        setPostalCode(value)
        resetInnerState()
        if (priceAreaPostalCode) {
          setPriceAreaPostalCode(undefined)
        }
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break

      case 'housingSize':
        setHousingSize(+value || undefined)
        break

      case 'housingType':
        setHousingType(value as HousingOptions)
        break

      case 'estimatedConsumption':
        setEstimatedConsumption(+value || undefined)
        break

      case 'city':
        setPlace(value)
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break

      case 'streetAddress':
        setStreetAddress(value)
        if (deliveryArea) {
          setDeliveryArea(undefined)
        }
        break
    }
  }

  //Debounce on postal code input
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        !Boolean(form.formState.errors.postalCode) &&
        !deliveryArea &&
        postalCode &&
        postalCode.length === 5 &&
        ((!streetAddress && !place) || (streetAddress && place))
      ) {
        setIsPriceAreaError(false) //reset error before refetch
        requestDeliveryArea(postalCode, streetAddress, place).catch(() => {
          setIsPriceAreaError(true)
        })
      }
    }, 1500)

    return () => clearTimeout(timeout)
  }, [postalCode, streetAddress, place, requestDeliveryArea])

  //Reset local state on postal code change
  useEffect(() => {
    if (priceAreaPostalCode) {
      setPostalCode(priceAreaPostalCode)
      resetInnerState()
    }
  }, [priceAreaPostalCode])

  //Trigger additional form on indecisive price area fetch
  useEffect(() => {
    if (isMultipleAreas) {
      setIsAdditionalFormVisible(isMultipleAreas)
      setDeliveryArea(undefined)
    }
  }, [isMultipleAreas])

  //Reset state if coming from Enterprise site
  useEffect(() => {
    if (usageOption) {
      resetHousingInfo()
    }
  }, [usageOption])

  return (
    <QuickSearchWrapper form={form}>
      <Box
        display="flex"
        flexDirection={flexDirection}
        flexWrap="wrap"
        gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
      >
        <Input
          {...QUICK_SEARCH_CONFIG.postalCode}
          texts={{
            topText: l('postalCode.topText'),
            label: l('postalCode.label'),
            errorMessage: l('postalCode.errorMessage'),
          }}
          onChange={onChange}
          isLoading={_isLoading}
          isError={isPriceAreaError}
          isSuccess={Boolean(deliveryArea)}
        />
        {isAdditionalFormVisible && <AdditionalInfoForm onChange={onChange} />}
        <HousingSelect
          {...QUICK_SEARCH_CONFIG.housingType}
          handleSelect={onChange}
          texts={{
            topText: l('housingType.topText'),
            label: l('housingType.label'),
            errorMessage: l('housingType.errorMessage'),
          }}
        />
        <Input
          {...QUICK_SEARCH_CONFIG.housingSize}
          onChange={onChange}
          texts={{
            topText: l('housingSize.topText'),
            label: l('housingSize.label'),
            errorMessage: l('housingSize.errorMessage'),
          }}
        />
      </Box>
      {shouldDisplayConsumptionRow && (
        <Box
          display="flex"
          flexDirection={flexDirection}
          gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
          minHeight={{ default: 'auto', l: px2rem(99) }}
        >
          {housingType && (
            <Consumption
              isConsumptionInputManual={isConsumptionInputManual}
              setIsConsumptionInputManual={setIsConsumptionInputManual}
              onChange={onChange}
            />
          )}
          {primaryLink && (
            <Box
              display="flex"
              flexDirection={{ default: 'column', l: 'row' }}
              flex="2"
              alignItems={{ default: 'center', l: 'flex-end' }}
              justifyContent="flex-end"
              mt={{ default: spacing.xxs, s: spacing.xs, l: spacing.none }}
              gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
            >
              <ButtonPill
                tag={Link}
                onClick={(event) => {
                  event.preventDefault()
                  router.push(getLinkEntryUrl(primaryLink))
                  stepEvent('find_contract', {
                    housing_type: housingType,
                    size: housingSize,
                    est_consumption: estimatedConsumption,
                  })
                }}
                data-testid="find-contracts-button"
                rightIcon={<IconArrowRight size={24} />}
              >
                {primaryLink.label}
              </ButtonPill>
            </Box>
          )}
        </Box>
      )}
    </QuickSearchWrapper>
  )
}
