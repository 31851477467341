import type { ReactNode } from 'react'
import { FormProvider, type UseFormReturn } from 'react-hook-form'

import { Box, shadows, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import type { QuickSearchForm } from '../../types'

type QuickSearchWrapperProps = {
  children?: ReactNode
  form: UseFormReturn<QuickSearchForm>
}

export const QuickSearchWrapper = ({ children, form }: QuickSearchWrapperProps) => {
  const { colors } = useTheme()
  return (
    <Box borderRadius={spacing.xxxs} backgroundColor={colors.backgroundPrimary}>
      <FormProvider {...form}>
        <form>
          <Box
            display="flex"
            flexDirection="column"
            gap={{ default: spacing.xxs, s: spacing.xs }}
            p={{ default: spacing.xxs, s: spacing.xs }}
            boxShadow={shadows.m}
            borderRadius={spacing.xxxs}
          >
            {children}
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}
