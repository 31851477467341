'use client'

import type { ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { Box, ContentText, IconCheck, Loader, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import { StyledInput } from '../../../Checkout/styled'
import type { FormFiledValues, QuickSearchForm } from '../../types'

type InputProps = FormFiledValues & {
  isLoading?: boolean
  isError?: boolean
  isSuccess?: boolean

  onChange?: (name: keyof QuickSearchForm, value: string) => void
  handleConsumptionChange?: (houseSize: string) => void
}

export const Input = ({
  texts,
  isLoading,
  isError,
  isSuccess,
  handleConsumptionChange,
  onChange,
  ...formProps
}: InputProps) => {
  const { topText, ...inputTexts } = texts
  const { colors } = useTheme()

  const {
    field: { ref, ...fieldProps },
    fieldState,
  } = useController({ ...formProps })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isLoading && /^\d*$/.test(event.target.value)) {
      onChange && onChange(fieldProps.name, event.target.value)
    }
  }

  const getIcon = () => {
    if (isLoading) {
      return <Loader />
    }
    if (isSuccess) {
      return <IconCheck color={colors.textSuccess} />
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxxs} flex="1">
      {topText && <ContentText color={colors.textSecondary}>{topText}</ContentText>}
      <StyledInput
        {...fieldProps}
        {...inputTexts}
        inputRef={ref}
        onChange={handleChange}
        error={Boolean(fieldState.error) || isError}
        data-testid={`quick-search-form-${formProps.name}`}
        rightIcon={getIcon()}
        inputProps={{
          maxLength: formProps.name === 'housingSize' ? 3 : 5,
          inputMode: 'numeric',
        }}
      />
    </Box>
  )
}
